// @flow
import React from 'react'
import { graphql, StaticQuery } from 'gatsby'
import Layout from '../components/layout'
import GridElement from '../components/grid-element'
import WithSize from '../utils/with-size'

const getEdges = data => data.allMarkdownRemark.edges

const query = graphql`
  query SpecialEditionPageQuery {
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { frontmatter: { categories: { eq: "special" } } }
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            path
            artist
            number
          }
        }
      }
    }
  }
`

const SpecialEditionPage = () => (
  <StaticQuery
    query={query}
    render={data => {
      const posts = getEdges(data)

      return (
        <Layout row centerContent>
          <WithSize>
            {({ small, medium }) =>
              posts.map(({ node }) => {
                const { frontmatter, id } = node
                return (
                  <GridElement type="se" medium={medium} small={small} {...frontmatter} key={id} />
                )
              })
            }
          </WithSize>
        </Layout>
      )
    }}
  />
)

export default SpecialEditionPage
